"use client";

import { Button } from "@/components/ui";
import { Icon } from "@iconify/react";
import { useEffect } from "react";

type ErrorPageProps = {
  error: Error & { digest?: string };
  reset: () => void;
};

export default function ErrorPage({ error, reset }: ErrorPageProps) {
  useEffect(() => {
    // Ensure error is defined before accessing properties
    if (error) {
      const errorDetails = {
        message: error.message || "Unknown error",
        digest: error.digest,
        stack: error.stack,
      };
      console.error("Error page encountered:", errorDetails);
    }
  }, [error]);

  const handleReset = () => {
    try {
      reset();
    } catch (e) {
      console.error("Reset failed:", e);
      window.location.reload();
    }
  };

  // Ensure error is defined, provide fallback UI if not
  if (!error) {
    return (
      <div className="flex min-h-screen flex-col items-center justify-center bg-gray-900 px-4">
        <h1 className="text-3xl font-extrabold text-white">
          An unknown error occurred
        </h1>
      </div>
    );
  }

  return (
    <div className="flex min-h-screen flex-col items-center justify-center bg-gray-900 px-4 sm:px-6 lg:px-8">
      <div className="w-full max-w-md space-y-8 text-center">
        <Icon
          className="mx-auto size-24 text-yellow-400"
          icon="heroicons:exclamation-triangle"
        />

        <h1 className="mt-6 text-3xl font-extrabold text-white">
          Oops! Something went wrong
        </h1>

        <p className="mt-2 text-sm text-gray-300">
          We apologize for the inconvenience. An unexpected error has occurred.
        </p>

        {error?.message && (
          <div className="mt-4 overflow-hidden bg-gray-800 shadow sm:rounded-lg">
            <div className="px-4 py-5 sm:p-6">
              <h3 className="text-lg font-medium leading-6 text-white">
                Error details
              </h3>

              <div className="mt-2 max-w-xl text-sm text-gray-300">
                <p>{error.message}</p>
              </div>

              {error.digest && (
                <div className="mt-3 text-sm">
                  <p className="font-medium text-gray-400">
                    Error ID: {error.digest}
                  </p>
                </div>
              )}
            </div>
          </div>
        )}

        <div className="mt-8">
          <Button
            className="flex w-full items-center justify-center bg-blue-600 text-white hover:bg-blue-700"
            onClick={handleReset}
            variant="default"
          >
            <Icon className="mr-2 size-5" icon="heroicons:arrow-path" />
            Try again
          </Button>
        </div>

        <p className="mt-4 text-xs text-gray-400">
          If the problem persists, please contact our support team.
        </p>
      </div>
    </div>
  );
}
